<template>
  <div class="pb-4">
    <div v-if="user.type === 0">
      <AccessoEPermessi />
      <ShowUserCode />
      <NotificationChannels class="my-4" />
    </div>
    <div v-else>
      <h5>Nessuna impostazione per questa utenza</h5>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import AccessoEPermessi from "@/components/profile/AccessoEPermessi.vue";
import ShowUserCode from "@/components/impostazioni/ShowUserCode.vue";
import NotificationChannels from "@/components/impostazioni/NotificationChannels.vue";


export default {
  name: "ImpostazioniCittadino",
  components: {
    AccessoEPermessi,
    ShowUserCode,
    NotificationChannels
  },
  computed: {
    ...mapState("utente", ["status", "user"]),
  },
  data() {
    return {};
  },

  methods: {
    ...mapActions("utente", ["me"]),
  },
  created() {
    this.me();
  }, beforeRouteLeave(to, from, next) {
    if (this.user.type !== 0) {
      next();
    }
    if (!this.user.expired_password) {
      next();
    }
  },
  // mounted() {
  //   console.log("mounted, company", this.company);
  // },
};
</script>
<style>
.text__control {
  white-space: initial;
  word-wrap: break-word;
  overflow-x: auto;
}
</style>
